import cx from 'classnames'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import { BeamButton } from '../../../stories/BeamButton'
import { BeamLogo } from '../../../stories/BeamLogo'
import { BeamTextfield } from '../../../stories/BeamTextfield'
import { BeamInputChangeEvent } from '../../../stories/BeamTextfield/BeamTextfield'
import { PageNotFound } from '../PageNotFound'
import $$ from './password-reset-page.module.css'
import { resetPasswordAuthAxios, resetPasswordAxios } from './PasswordResetPage.api'
import { authErrorHelper } from './PasswordResetPage.helper'

interface PasswordResetProps {
  match?: {
    params: {
      userId: number
      token: string
    }
  }
}

export const PasswordResetPage = ({ match }: PasswordResetProps) => {
  const [userData, setUserData] = useState({
    email: '',
    firstName: '',
    lastName: '',
    password: '',
    confirmPassword: '',
  })

  const [fetched, setFetched] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [submissionError, setSubmissionError] = useState<null | string>(null)
  const [authError, setAuthError] = useState<null | string>(null)
  const [submitted, setSubmitted] = useState<boolean>(false)

  async function getAuth(userId: number, token: string) {
    try {
      const res = await resetPasswordAuthAxios(userId, token)
      setLoading(false)
      setFetched(true)
      setUserData(prev => ({
        ...prev,
        email: res.data.email,
        firstName: res.data.firstName,
        lastName: res.data.lastName,
      }))
    } catch (error: any) {
      setLoading(false)
      setAuthError(authErrorHelper(error))
      setFetched(true)
    }
  }

  useEffect(() => {
    if (fetched || loading) return
    if (!match?.params.userId || !match?.params.token) return

    const {
      params: { userId, token },
    } = match

    setLoading(true)

    getAuth(userId, token).catch(error => {
      console.error(error)
      setAuthError(error.message)
    })
  }, [fetched, loading, match])

  if (authError) return AuthErrorPage(authError)

  if (!match?.params.userId || !match?.params.token) return <PageNotFound />

  const captureValue: BeamInputChangeEvent = e => {
    const target = e.target as HTMLInputElement
    if (target?.name) {
      const newCredentials = {
        ...userData,
        [target.name]: target.value,
      }

      setUserData(newCredentials)
    }
  }

  const {
    params: { userId, token },
  } = match

  const handleSubmit = async (event: any) => {
    event.preventDefault()

    const { password, confirmPassword } = userData

    try {
      await resetPasswordAxios(password, confirmPassword, userId, token)
      setSubmitted(true)
    } catch (error: any) {
      setSubmissionError(error.response.data.detail)
    }
  }

  return (
    <div className={cx($$.passwordResetPage, 'w-full h-full beam-bg--gradient-coral')}>
      <div className="flex items-center justify-center h-screen min-h-full px-4 py-12 sm:px-6 lg:px-8">
        <div
          className={cx(
            $$.passwordResetForm,
            'flex flex-col items-center justify-center flex-grow w-full h-full max-w-2xl p-2 space-y-8 text-center desktop:p-28 desktop:h-auto'
          )}>
          <BeamLogo alt="Beam Impact Partner Portal - Reset Password Form" />
          {submitted ? (
            <>
              <p>Your password was reset</p>
              <Link to={`/login`}>Return to login</Link>
            </>
          ) : (
            <form onSubmit={handleSubmit} className="w-full space-y-6">
              <p>
                Hi {userData.firstName} {userData.lastName},
                <br />
                Use the following form to reset your password, it is valid for 15 minutes
                <br />
              </p>
              <div className="w-full space-y-6">
                <BeamTextfield
                  name="password"
                  placeholder="Password"
                  type="password"
                  onChange={captureValue}
                  isPlainInput
                  autofocus
                />
                <BeamTextfield
                  name="confirmPassword"
                  placeholder="Confirm Password"
                  type="password"
                  onChange={captureValue}
                  autofocus
                />
              </div>
              <BeamButton label="Submit" variant="elevated" type="submit" />
              <div style={{ color: 'var(--beam-color--error)' }}>{submissionError}</div>
            </form>
          )}
        </div>
      </div>
    </div>
  )
}

const AuthErrorPage = (res: string) => {
  return (
    <div className={cx($$.passwordResetPage, 'w-full h-full beam-bg--gradient-coral')}>
      <div className="flex items-center justify-center h-screen min-h-full px-4 py-12 sm:px-6 lg:px-8">
        <div
          className={cx(
            $$.passwordResetForm,
            'flex flex-col items-center justify-center flex-grow w-full h-full max-w-2xl p-2 space-y-8 text-center desktop:p-28 desktop:h-auto'
          )}>
          <BeamLogo alt="Beam Impact Partner Portal - Reset Password Form" />
          <p>{res}</p>
        </div>
      </div>
    </div>
  )
}
