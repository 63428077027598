import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router'

import { useBeamSelector } from '../../../hooks'
import { sendSupportEmail } from '../../../redux/thunks/partnerThunks'
import { BeamButton } from '../../../stories/BeamButton'
import { BeamTextarea } from '../../../stories/BeamTextarea'
import { BeamTextfield } from '../../../stories/BeamTextfield'
import { BeamInputChangeEvent } from '../../../stories/BeamTextfield/BeamTextfield'
import { BeamToast } from '../../../stories/BeamToast'
import { APIError } from '../../root/APIError'
import { BeamSEO } from '../../root/BeamSEO'
import $$ from './contact-support-page.module.css'

const REFERRAL_SUBJECT = 'Tell me about Beam’s referral program'
const REFERRAL_MESSAGE =
  'I want to learn more about Beam’s referral program. I would like to refer…(optional)'

export const ContactSupportPage = () => {
  const user = useBeamSelector(({ user }) => user)
  const loadingStates = useBeamSelector(({ loadingStates }) => loadingStates) as any
  const dispatch = useDispatch()

  const { search } = useLocation()
  const params = search.replace('?', '')

  const prefill_subject = params === 'refill=referral' ? REFERRAL_SUBJECT : ''
  const prefill_message = params === 'refill=referral' ? REFERRAL_MESSAGE : ''

  const initialEmailData = {
    message: prefill_message,
    subject: prefill_subject,
    name: user?.firstName || '',
    email: user?.email || '',
  }

  const [emailData, setEmailData] = useState(initialEmailData)
  const [openToast, setOpenToast] = useState<boolean>(false)
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false)

  const captureValue: BeamInputChangeEvent = e => {
    const target = e.target
    if (target?.name) {
      const newEmailData = {
        ...emailData,
        [target.name]: target.value,
      }

      setEmailData(newEmailData)
    }
  }

  const sendEmail = () => {
    dispatch(sendSupportEmail(emailData, user.partnerId))
    setOpenToast(true)
    setIsSubmitted(true)
  }

  if (loadingStates.supportEmail?.success && isSubmitted) {
    setEmailData(initialEmailData)
    setIsSubmitted(false)
  }

  if (loadingStates.user && loadingStates.user.loading)
    return <div className="loading-message">One moment please...</div>
  if (loadingStates.user && loadingStates.user.error)
    return <APIError error={loadingStates.user.error} withRedirect={true} />

  return (
    <>
      <BeamSEO title={`Contact Us`} />
      <div id="ContactSupportPage" className="float-left">
        <h1 className="beam--heading--1">Contact Us</h1>
        <p className={$$.description}>
          {' '}
          Contact us for any issue or question you have, if urgent, please call or email your Beam
          Representative.
        </p>
        <h2 className="beam--heading--2">Message Support</h2>

        <div className="max-w-xl space-y-6">
          <BeamTextfield
            name="name"
            label="Name"
            variant="small"
            onChange={captureValue}
            value={emailData.name}
          />
          <BeamTextfield
            name="email"
            label="Email"
            variant="small"
            onChange={captureValue}
            value={emailData.email}
          />
          <BeamTextfield
            name="subject"
            label="Subject"
            variant="small"
            onChange={captureValue}
            value={emailData.subject}
          />
          <BeamTextarea
            name="message"
            label="Message"
            placeholder="Message here"
            value={emailData.message}
            onChange={captureValue as any}
          />
          <BeamButton label="Send Message" variant="elevated" onClick={() => sendEmail()} />
        </div>
        {![null, undefined].includes(loadingStates.supportEmail?.success) && (
          <BeamToast
            onClose={() => setOpenToast(false)}
            open={openToast}
            closable={true}
            text={
              loadingStates.supportEmail.success
                ? 'Message Sent!'
                : errorMessageHelper(loadingStates.supportEmail.error)
            }
            variant={loadingStates.supportEmail.success ? 'success' : 'error'}
            icon={loadingStates.supportEmail.success ? <span>&#127881;</span> : null}
          />
        )}
      </div>
    </>
  )
}

const errorMessageHelper = (error: any) => {
  const res = JSON.stringify(
    (error && error.response && error.response.data && error.response.data.error) || error
  )

  if (res?.includes('Unauthorized')) {
    return 'You are unauthorized to send this email. Please try logging back in or reach out to us using another method. '
  }
  if (res?.includes('name')) {
    return 'Please input a name'
  }
  if (res?.includes('subject')) {
    return 'Please input a subject'
  }
  if (res?.includes('message')) {
    return 'Please input a message'
  }
  if (res?.includes('email')) {
    return 'Please input a valid email'
  }
  return 'Seems like something went wrong. Please try reaching out to us using another method or try again later.'
}
