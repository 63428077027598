import cx from 'classnames'
import { ReactNode } from 'react'

interface CenteredContentProps {
  children: ReactNode
  /**
   * Use `100vh` instead of `100%` for height
   */
  useVH?: boolean
  /**
   * Set this to true if this component is being placed inside the `beam--page-content` container.
   * True center-alignment in this context appears too low relative to the sidenav, so we instead use an offset.
   */
  inLayoutContainer?: boolean
}

// Simple component that fills full width and height of its parent and centers the content
export const CenteredContent = ({
  children,
  useVH = false,
  inLayoutContainer = false,
}: CenteredContentProps) => {
  return (
    <div
      className={cx('flex flex-col items-center justify-center w-full h-full', {
        'h-full': !useVH,
        'h-screen': useVH,
        'pt-[250px]': inLayoutContainer,
      })}>
      {children}
    </div>
  )
}
