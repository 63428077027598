import cx from 'classnames'
import { useEffect, useState } from 'react'

import { BeamCard } from '../BeamCard'
import { BeamDSProps } from '../interface'
import { XIcon } from './assets/x'
import $$ from './beam-cta.module.css'

interface BeamCTAProps extends BeamDSProps {
  /**
   * Icon to display to the left
   */
  icon?: React.ReactNode
  /**
   * Left Content - DOM elements that appear on the left/top of the CTA
   */
  leftContent: string | React.ReactNode
  /**
   * Right Content - DOM elements that appear on the right/bottom of the CTA
   */
  rightContent: string | React.ReactNode
  /**
   * Background Color
   */
  backgroundColor?: string
  /**
   * canClose - Allows the CTA to be hidden on close
   */
  canClose?: boolean
  /**
   * canCloseUniqueKey - A unique key to identify which CTA should be closed
   */
  canCloseUniqueKey?: string
  /**
   * The button's click behavior
   */
  onClick?: () => void
}

const TRUE_VALUE = '1'

function localStorageKey(key: string) {
  return `hasSeen-${key}`
}
function setHasSeenBanner(key: string) {
  localStorage.setItem(localStorageKey(key), TRUE_VALUE)
}
function getHasSeenBanner(key: string) {
  return localStorage.getItem(localStorageKey(key))
}

/**
 * Call to Action component
 */
export const BeamCTA = ({
  leftContent,
  rightContent,
  icon = '',
  backgroundColor = '--beam-color--sun-100',
  onClick,
  canClose = false,
  canCloseUniqueKey = '',
  ...props
}: BeamCTAProps) => {
  const [isBannerVisible, setIsBannerVisible] = useState(getHasSeenBanner(canCloseUniqueKey))

  useEffect(() => {
    // Trigger rerender when user closes the banner
  }, [isBannerVisible])

  if (!!canClose && getHasSeenBanner(canCloseUniqueKey) === '1') {
    return null
  }

  return (
    <BeamCard
      {...props}
      excludeBorder
      className={cx($$.cta, props.className)}
      backgroundColor={backgroundColor}>
      <div className={$$.row}>
        {icon && <div className={$$.icon}>{icon}</div>}

        <div className={$$.left}>{leftContent}</div>
        <div className={$$.right}>{rightContent}</div>
        {canClose && (
          <div className={$$.close}>
            <button
              onClick={() => {
                setHasSeenBanner(canCloseUniqueKey)
                setIsBannerVisible(TRUE_VALUE)
              }}>
              <XIcon />
            </button>
          </div>
        )}
      </div>
    </BeamCard>
  )
}
