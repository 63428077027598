import { getV2ApiUrl } from '../api/auth'
import { useBeamSelector } from '../hooks'
import { TEnrollmentDataResponse, TUser } from '../utils/types'
import { useBeamFetch } from './useBeamFetch'

export const useFetchEnrollmentData = () => {
  const user: TUser | undefined = useBeamSelector(({ user }) => user)
  const {
    data: enrollmentData,
    isLoading,
    error,
    refetch,
  } = useBeamFetch<TEnrollmentDataResponse>(
    `${getV2ApiUrl()}/enhanced-features/partner/${user?.partnerId}/enrollment`,
    {
      method: 'GET',
    }
  )

  return { enrollmentData, isLoading, error, refetch }
}
