import { useEffect, useState } from 'react'
import { Link, Redirect, useLocation } from 'react-router-dom'

import { getV2ApiUrl } from '../../../api/auth'
import { BeamLoadingIndicator } from '../../../stories/BeamLoadingIndicator'
import { axiosRequest } from '../../../utils/axiosRequest'
import { GoToOverviewButton } from './components/GoToOverviewButton'
import { ShopifyContentWrapper } from './ShopifyContentWrapper'
import { ShopifyReferenceError } from './ShopifyReferenceError'
import { LocationShopifyStates, OnboardingStepParamValues } from './types'

interface AssociatePendingRequestApiResponse {
  message?: string
  shopifyOnboardingRequest?: {
    id?: string
    email?: string
  }
}

/**
 * This page is reached if onboarding_step is:
 * - contact
 * - awaiting_approval
 * - existing_chain_awaiting_approval
 */
export const ShopifyAppAssociatePage = () => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState('')
  const [apiResponse, setApiResponse] = useState<AssociatePendingRequestApiResponse | null>(null)
  const location = useLocation<LocationShopifyStates>()
  const onboardingStep = location?.state?.onboardingStep
  const shopifyOnboardingRequestId = location?.state?.shopifyOnboardingRequestId || ''

  useEffect(() => {
    ;(async () => {
      try {
        const res = await axiosRequest(
          'POST',
          getV2ApiUrl('/shopify-public-app/associate-pending-request-with-chain'),
          {
            shopifyOnboardingRequestId,
          }
        )
        const data: AssociatePendingRequestApiResponse = res.data
        setApiResponse(data)
        setLoading(false)
      } catch (error: any) {
        setError(error.message)
        setLoading(false)
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (loading) {
    return (
      <ShopifyContentWrapper>
        <BeamLoadingIndicator />
      </ShopifyContentWrapper>
    )
  }

  if (error) {
    return (
      <ShopifyContentWrapper>
        <h2 className={'text-center'}>
          We encountered an error associating the Shopify app to your Beam account.
          <br />
          Please try again or contact support.
        </h2>

        <ShopifyReferenceError errorMessage={error} />

        <GoToOverviewButton />
        <div className={'mt-5'}>
          <Link to={`/contact-support`}>Contact Support</Link>
        </div>
      </ShopifyContentWrapper>
    )
  }

  if (
    onboardingStep === OnboardingStepParamValues.CONTACT ||
    onboardingStep === OnboardingStepParamValues.AWAITING_APPROVAL
  ) {
    return (
      <ShopifyContentWrapper>
        <h2 className={'text-center'}>
          It looks like you&apos;re trying to set up Shopify with an existing partner. Beam will
          reach out to {apiResponse?.shopifyOnboardingRequest?.email || 'you'} soon.
        </h2>
        <GoToOverviewButton />
      </ShopifyContentWrapper>
    )
  }

  // if onboarding_step = existing_chain_awaiting_approval
  return <Redirect to={`/`} />
}
