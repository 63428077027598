import { AxiosResponse, Method } from 'axios'
import { isNil } from 'lodash'
import { useCallback, useEffect, useState } from 'react'

import { axiosRequest } from '../utils/axiosRequest'

/**
 * Generic data fetching hook that uses `fetch`.
 */
export function useBeamFetch<Res = unknown>(url: string, options: RequestInit) {
  const [data, setData] = useState<Res | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isFetching, setIsFetching] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)

  const fetchData = useCallback(async () => {
    setIsLoading(true)
    setIsFetching(true)
    setError(null)

    try {
      const response = (await axiosRequest(
        options?.method as Method,
        url,
        options.body
      )) as AxiosResponse<Res>
      setData(response.data)
    } catch (err) {
      console.error(err)
      setError(err instanceof Error ? err.message : 'An unknown error occurred.')
    } finally {
      setIsLoading(false)
      setIsFetching(false)
    }
  }, [url, options])

  // Initially fetch data when the component mounts or url/options change
  useEffect(() => {
    if (isNil(data) && !isFetching) {
      fetchData()
    }
  }, [data, fetchData, isFetching])

  return { data, isLoading, error, refetch: fetchData }
}
