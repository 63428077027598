import { Link } from 'react-router-dom'

export const UpgradeWidgetCTA = () => {
  return (
    <div className="px-4 py-3 rounded-lg bg-lime-500 text-moss-800">
      <div className="font-semibold">New Boost UI is Here!</div>
      <span className="font-apfel">
        Convert customers up to 4x more when you upgrade to v1.38.0{' '}
      </span>
      <a className="font-semibold">
        <Link to={`/contact-support`}>Upgrade Now</Link>
      </a>
    </div>
  )
}
