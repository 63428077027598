import cx from 'classnames'

export const ShopifyReferenceError = ({
  errorMessage,
  classNames,
}: {
  errorMessage: string
  classNames?: string
}) => (
  <div className={cx('p-4 bg-cherry-50 rounded font-bold text-cherry-800 max-w-2/3', classNames)}>
    Reference Error: <code>{errorMessage}</code>
  </div>
)
