import { IconComponentProps } from './IconComponentProps'

export const LockIcon = ({ fill = '--beam-color--charcoal-500' }: IconComponentProps) => (
  <svg style={{ width: '100%' }} viewBox="0 0 24 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3 32C2.175 32 1.469 31.7018 0.882 31.1055C0.295 30.5092 0.001 29.7915 0 28.9524V13.7143C0 12.8762 0.294 12.159 0.882 11.5627C1.47 10.9663 2.176 10.6677 3 10.6667H4.5V7.61905C4.5 5.51111 5.2315 3.71454 6.6945 2.22933C8.1575 0.744128 9.926 0.00101691 12 1.04014e-06C14.074 -0.00101483 15.843 0.742096 17.307 2.22933C18.771 3.71657 19.502 5.51314 19.5 7.61905V10.6667H21C21.825 10.6667 22.5315 10.9653 23.1195 11.5627C23.7075 12.16 24.001 12.8772 24 13.7143V28.9524C24 29.7905 23.7065 30.5082 23.1195 31.1055C22.5325 31.7029 21.826 32.001 21 32H3ZM12 24.381C12.825 24.381 13.5315 24.0828 14.1195 23.4865C14.7075 22.8902 15.001 22.1724 15 21.3333C14.999 20.4942 14.7055 19.777 14.1195 19.1817C13.5335 18.5864 12.827 18.2877 12 18.2857C11.173 18.2837 10.467 18.5823 9.882 19.1817C9.297 19.7811 9.003 20.4983 9 21.3333C8.997 22.1684 9.291 22.8861 9.882 23.4865C10.473 24.0869 11.179 24.385 12 24.381ZM7.5 10.6667H16.5V7.61905C16.5 6.34921 16.0625 5.26984 15.1875 4.38095C14.3125 3.49206 13.25 3.04762 12 3.04762C10.75 3.04762 9.6875 3.49206 8.8125 4.38095C7.9375 5.26984 7.5 6.34921 7.5 7.61905V10.6667Z"
      fill={`var(${fill})`}
    />
  </svg>
)
