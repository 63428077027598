import { Link } from 'react-router-dom'

import { useFeatureFlags } from '../../../hooks/useFeatureFlags'
import { BeamButton } from '../../../stories/BeamButton'
import { BeamCTA } from '../../../stories/BeamCTA'
import { UpcomingPromosInfo } from '../../../utils/types'
import { ReactComponent as RocketIcon } from './assets/rocket.svg'

export const OverviewNotifications = ({
  autopayCTAComponent,
  promos = [],
  shouldDisplayAutopayCTA = false,
}: {
  autopayCTAComponent: any
  promos?: UpcomingPromosInfo[]
  shouldDisplayAutopayCTA?: boolean
}) => {
  const featureFlags = useFeatureFlags()

  if (shouldDisplayAutopayCTA) {
    return autopayCTAComponent
  }

  if (!featureFlags['can-see-promos-ctas']) {
    return null
  }

  const displayLinkToCampaigns =
    featureFlags['can-see-campaign-management-feature'] === undefined ||
    featureFlags['can-see-campaign-management-feature'] === true

  return <PromosNotifications promos={promos} displayCampaignsLink={displayLinkToCampaigns} />
}

export const PromosNotifications = ({
  promos = [],
  displayCampaignsLink,
}: {
  promos: UpcomingPromosInfo[]
  displayCampaignsLink: boolean
}) => {
  return (
    <div className={`${promos.length > 0 ? 'pt-3' : ''}`}>
      {promos.map(({ campaignName, daysUntilLive }, i) => {
        let header = ''
        const subheader = `Promote your "${campaignName}" campaign to your customers to maximize results.`

        const daysUntilLiveRounded = Math.floor(daysUntilLive)
        if (daysUntilLive > 1) {
          header = `Your Upcoming Boosted Impact Campaign is Launching In ${daysUntilLiveRounded} Day${
            daysUntilLiveRounded > 1 ? 's' : ''
          }`
        } else if (daysUntilLive <= 1 && daysUntilLive > 0) {
          header = `Your Upcoming Boosted Impact Campaign is Launching Soon!`
        } else if (daysUntilLive === 0) {
          header = `Your Boosted Impact Campaign Is Live!`
        }

        return (
          <PromosCTA
            key={i}
            header={header}
            subheader={subheader}
            displayCampaignsLink={displayCampaignsLink}
          />
        )
      })}
    </div>
  )
}

const PromosCTA = ({
  header,
  subheader,
  displayCampaignsLink,
}: {
  header: string
  subheader: string
  displayCampaignsLink: boolean
}) => {
  return (
    <BeamCTA
      leftContent={
        <>
          <h3 className="flex m-0 my-2 text-sky-600">
            <span className="inline-block align-middle">
              <RocketIcon />
            </span>{' '}
            <span className="ml-2 leading-5">{header}</span>
          </h3>
          <p className="beam--paragraph--small text-sky-600">
            {subheader}{' '}
            {displayCampaignsLink && (
              <Link
                to={`/nonprofits/campaigns`}
                className={`mt-2 font-bold text-sky-600 tracking-wide`}>
                Go to Boosted Campaigns
              </Link>
            )}
          </p>
        </>
      }
      rightContent={
        <>
          <BeamButton
            label={'Download Marketing Assets'}
            onClick={() =>
              window.open(
                'https://drive.google.com/drive/folders/1yy3zsgY-oy1gntCv95wITWOGP19Trasd?usp=sharing',
                '_blank'
              )
            }
            variant={'basic_blue'}
            block={true}
            size="small"
          />
        </>
      }
      backgroundColor="--beam-color--sky-50"
    />
  )
}
