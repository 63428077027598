import { Redirect } from 'react-router-dom'

import { useBeamSelector } from '../../hooks'
import { isAuthenticated } from '../../utils/helpers/isAuthenticated'
import { TUser } from '../../utils/types'
import { APIError } from '../root/APIError'

export const PartnerHome = (): JSX.Element | null => {
  const user: TUser | undefined = useBeamSelector(({ user }) => user)
  const loadingStates = useBeamSelector(({ loadingStates }) => loadingStates) as any

  if (loadingStates.user?.loading)
    return <div className="loading-message">One moment please...</div>
  if (loadingStates.user?.error)
    return <APIError error={loadingStates.user.error} withRedirect={true} />
  if (isAuthenticated(user)) {
    return <Redirect to={`/overview`} />
  }

  if (!user) return null

  return (
    <div>
      <h1>Page no longer in service</h1>
    </div>
  )
}
