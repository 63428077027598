import cx from 'classnames'
import { isNil } from 'lodash'
import { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'

import { fetchStripeData } from '../../../api/stripeApi'
import { useBeamSelector } from '../../../hooks'
import { useFeatureFlags } from '../../../hooks/useFeatureFlags'
import { BeamButton } from '../../../stories/BeamButton'
import { BeamCTA } from '../../../stories/BeamCTA'
import { BeamMiniCTA } from '../../../stories/BeamMiniCTA'
import { BeamToast } from '../../../stories/BeamToast'
import { OverviewPartnerImpactResponse, TSite } from '../../../utils/types'
import { BeamSEO } from '../../root/BeamSEO'
import { InternalDevPanel } from '../../root/InternalDevPanel'
import { Prize } from '../InvoicePage/assets/Prize'
import {
  PaymentsSetupModals,
  PaymentsSetupModalsContext,
  PaymentsSetupModalsEnums,
} from '../PaymentsModal'
import {
  autopaySuccessful,
  initAutopayLaunchLocalStorageKey,
  onClickAutopayCTA,
} from '../PaymentsModal/PaymentsModalUtils'
import { PaypalGivingFundStatusEnums } from '../PaypalGivingFundPage'
import { ReactComponent as MoneyCog } from './assets/moneyCog.svg'
import { ReactComponent as RainbowIcon } from './assets/rainbow.svg'
import { ReactComponent as RightArrow } from './assets/rightArrow.svg'
import { BusinessImpactModule } from './BusinessImpactModule'
import { CumulativeSocialImpactSection } from './CumulativeImpactSection'
import { MonthlyCustomerEngagementSection } from './MonthlyCustomerEngagementSection'
import { ActiveNonprofitPartnersBlock, ImpactFromActiveNonprofits } from './NonprofitSection'
import {
  fetchCumulativeImpact,
  fetchOptimaRoi,
  fetchPartner,
  holidayCumulativeImpactDataPartnerRequest,
} from './Overview.api'
import $$ from './overview-page.module.css'
import { OverviewNotifications } from './OverviewNotifications'
import {
  BusinessImpactStateWithLoading,
  ROIModuleState,
  TStateWithLoading,
} from './OverviewPage.types'
import { OverviewPageClone } from './OverviewPageClone'

const ErrorStateNoData = () => {
  return (
    <div className="flex items-center justify-center w-full h-full">
      <p className="font-bold">
        There was a problem fetching your data. Please try refreshing the page or{' '}
        <Link to={`/contact-support`}>contact support</Link> if the problem persists.
      </p>
    </div>
  )
}

const ErrorStateNoPartner = () => {
  return (
    <div>
      Your account does not have a partner associated with it. Please{' '}
      <Link to={`/contact-support`}>contact us</Link> to resolve this issue
    </div>
  )
}

interface OverviewPageProps {
  fetchOverviewsMethod?: typeof fetchPartner
  fetchRoiMethod?: typeof fetchOptimaRoi
  fetchCumulativeImpactMethod?: typeof fetchCumulativeImpact
  fetchStripDataMethod?: typeof fetchStripeData
}

export const OverviewPage = ({
  fetchOverviewsMethod = fetchPartner,
  fetchRoiMethod = fetchOptimaRoi,
  fetchCumulativeImpactMethod = fetchCumulativeImpact,
  fetchStripDataMethod = fetchStripeData,
}: OverviewPageProps) => {
  const history = useHistory()

  const initialDataState = { data: null, loading: false, error: '' }
  const user = useBeamSelector(({ user }) => user)

  const [hasDataFetchingError, setHasDataFetchingError] = useState(false)

  // TODO: These should all be typed.
  const [partnerData, setPartnerData] = useState<TStateWithLoading>(initialDataState)

  const [impactData, setImpactData] =
    useState<TStateWithLoading<OverviewPartnerImpactResponse>>(initialDataState)
  const [cumulativeImpactData, setCumulativeImpactData] =
    useState<TStateWithLoading>(initialDataState)

  const [calculatedData, setCalculatedData] =
    useState<BusinessImpactStateWithLoading>(initialDataState)
  const [stripeData, setStripeData] = useState<TStateWithLoading>(initialDataState)
  const [invoiceEmails, setInvoiceEmails] = useState({ invoiceEmail: '', invoiceCcEmails: [] })
  const [openToast, setOpenToast] = useState<boolean>(false)
  const [isAutopaySuccessful, setIsAutopaySuccessful] = useState<boolean>(false)
  const [activeModal, setActiveModal] = useState<PaymentsSetupModalsEnums | null>(null)
  const [showHolidayCumulativeImpactRequestModal, setShowHolidayCumulativeImpactRequestModal] =
    useState<boolean>(false)
  const [statusFromHolidayCumulativeImpactRequest, setStatusFromHolidayCumulativeImpactRequest] =
    useState<boolean>(false)

  const currentSiteFilter: TSite | null = useBeamSelector(({ site }) => site)

  const showAutopayLaunchModal = localStorage.getItem(initAutopayLaunchLocalStorageKey) || 0
  const partnerId = user?.partnerId
  const canSeeAutopayFeatures = !!user?.partner?.canSeeAutopayFeatures

  // show initial launch autopay modal
  useEffect(() => {
    if (
      canSeeAutopayFeatures &&
      user?.autopayModalSeenCount < 10 &&
      showAutopayLaunchModal < user.autopayModalSeenCount
    ) {
      setActiveModal(PaymentsSetupModalsEnums.setupBeamFeesAutoPayLanding)
    }
  }, [canSeeAutopayFeatures, showAutopayLaunchModal, user?.autopayModalSeenCount])

  // Shows success modal if query params are present after redirect
  useEffect(() => {
    autopaySuccessful({ history, changeActiveModal })
      .then(({ success, skipped }) => {
        if (skipped) return

        setIsAutopaySuccessful(success)
      })
      .catch(error => {
        console.error(error)
        setIsAutopaySuccessful(false)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Displays toast after autopay setup is successful
  useEffect(() => {
    if (isAutopaySuccessful && !activeModal) {
      setOpenToast(true)
    }
  }, [isAutopaySuccessful, activeModal])

  // Fetch initial data
  useEffect(() => {
    if (partnerId) {
      fetchAllData().catch(error => {
        setHasDataFetchingError(true)

        console.error('ERROR -->', error)
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Fetch calculated data after loading partnerData
  useEffect(() => {
    if (!partnerData.data || partnerData.loading || partnerData.error) {
      return
    }

    // avoid refetching calculated data if we already fetched it
    if (calculatedData.data) return

    getCalculatedData().catch(error => {
      console.error(error)
    })
  }, [calculatedData.data, getCalculatedData, partnerData])

  useEffect(() => {
    fetchCumulativeImpactData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // re-fetches roi data when store filter changes
  useEffect(() => {
    const currentStoreId = currentSiteFilter?.storeId || null
    const roiDataStoreId = calculatedData.data?.storeId || null
    const shouldSkip =
      currentStoreId == roiDataStoreId ||
      calculatedData.loading ||
      calculatedData.error ||
      !partnerData.data

    if (shouldSkip) return

    getCalculatedData().catch(error => {
      console.error(error)
    })
  }, [
    calculatedData.data?.storeId,
    calculatedData.error,
    calculatedData.loading,
    currentSiteFilter?.storeId,
    getCalculatedData,
    partnerData.data,
  ])

  // Fetch stripeData
  useEffect(() => {
    if (!canSeeAutopayFeatures || !user?.chainId) return

    const { data, loading, error } = stripeData
    if (data || loading || error) return

    setStripeData({ data: null, loading: true, error: null })

    fetchStripDataMethod(user?.chainId)
      .then(stripeData => {
        setStripeData({ data: stripeData, loading: false, error: null })
      })
      .catch(error => {
        console.error(error)
        setStripeData({ data: null, loading: false, error: error as any })
      })
  }, [user?.chainId, stripeData, canSeeAutopayFeatures, fetchStripDataMethod])

  useEffect(() => {
    setInvoiceEmails({
      invoiceEmail: partnerData?.data?.invoiceEmail || '',
      invoiceCcEmails: partnerData?.data?.invoiceCcEmails || [],
    })
  }, [partnerData?.data?.invoiceEmail, partnerData?.data?.invoiceCcEmails])

  const featureFlags = useFeatureFlags()
  if (featureFlags['subscription-reporting']) {
    return <OverviewPageClone />
  }

  // BEGIN Data fetching methods

  async function fetchAllData() {
    await Promise.all([fetchOverviewData()])
  }

  async function fetchOverviewData() {
    if (!partnerId || !user?.chainId || partnerData.loading || partnerData.error) return

    setPartnerData({ data: null, loading: true, error: null })
    setImpactData({ data: null, loading: true, error: null })

    try {
      const overviewData = await fetchOverviewsMethod(partnerId)
      setPartnerData({ data: overviewData.partner, loading: false, error: null })
      setImpactData({ data: overviewData.impact, loading: false, error: null })
    } catch (error) {
      console.error('Error fetching overview data:', error)
      setPartnerData({ data: null, loading: false, error: error as any })
      setImpactData({ data: null, loading: false, error: error as any })
    }
  }

  async function fetchCumulativeImpactData() {
    if (!user?.chainId) return
    const { data, loading, error } = cumulativeImpactData
    if (data || loading || error) return

    setCumulativeImpactData({ data: null, loading: true, error: null })

    try {
      const cumulativeImpactData = await fetchCumulativeImpactMethod(user?.chainId)

      setCumulativeImpactData({ data: cumulativeImpactData, loading: false, error: null })
    } catch (error) {
      console.error('Error fetching cumulative impact data:', error)
      setCumulativeImpactData({ data: null, loading: false, error: error as any })
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  async function getCalculatedData(): Promise<void> {
    if (calculatedData.loading || calculatedData.error || !partnerData.data) return

    setCalculatedData({ data: null, loading: true, error: null })

    try {
      const storeId =
        currentSiteFilter && currentSiteFilter.storeId !== null
          ? currentSiteFilter.storeId
          : undefined

      const roiData = await fetchRoiMethod(partnerId, storeId)

      setCalculatedData({ data: roiData, loading: false, error: null })
      return
    } catch (error) {
      console.error('Error fetching calculatedData: ', error)
      setCalculatedData({ data: null, loading: false, error: error as any })
    }
  }

  // END Data fetching methods

  function afterModalCloseHandler(closedModal: PaymentsSetupModalsEnums | null) {
    // The onCloseCallback event gets triggered after the context has been updated, causing modals to close when transitioning from one modal to another.
    if (activeModal && activeModal !== closedModal) {
      return
    }

    setActiveModal(null)

    // Set the number of times the user has seen the modal locally so we know not to show it again
    // in the same session.
    localStorage.setItem(initAutopayLaunchLocalStorageKey, user.autopayModalSeenCount || 0)
  }

  function changeActiveModal(newModal: PaymentsSetupModalsEnums | null) {
    if (newModal !== activeModal) {
      setActiveModal(newModal)
    }
  }

  if (!partnerId || !user.partner) return <ErrorStateNoPartner />
  if (hasDataFetchingError) return <ErrorStateNoData />

  const enabledAutopayBeamFees = !!stripeData.data?.autopay
  const enabledAutopayNonprofits =
    user?.partner?.ppgfStatus === PaypalGivingFundStatusEnums.enrolled

  // Grabs current state of cumulative impact request from the database so we don't allow
  // users to re-request them
  let hasPartnerRequestedCumulativeImpactDataForThisYear = user.cumulativeImpactRequest

  // This validates whether the user clicked on the button.  Provided the API response is a 200
  // we want to change the CTA dynamically to show that the request was sent.  This conditional
  // handles that.
  if (statusFromHolidayCumulativeImpactRequest) {
    hasPartnerRequestedCumulativeImpactDataForThisYear = true
  }

  const shouldDisplayAutopayCTA =
    user.upcomingPromos.length === 0 &&
    canSeeAutopayFeatures &&
    (!enabledAutopayBeamFees ||
      (!enabledAutopayNonprofits && !user.partner.inKindDonations.displayInKindDonations))

  const AutopayCTAComponent = (
    <div className={'mt-3 mb-6'}>
      <BeamCTA
        backgroundColor="--beam-color--sky-50"
        leftContent={
          <div>
            <h3 className="m-0 text-sky-600">
              <span className="inline-block mr-2 align-middle">
                <MoneyCog />
              </span>
              <span className="inline-block align-middle">Automate Your Payments</span>
            </h3>
            <p className="beam--paragraph--small text-sky-600">
              {enabledAutopayBeamFees && !enabledAutopayNonprofits
                ? 'Take 2 minutes to set up Autopay for Donations and we’ll automatically process your payment each time it is due, so you don’t have to think about it.'
                : 'Take 2 minutes to set up Autopay for Beam Fees and Autopay for Donations and we’ll automatically process your payments each time they’re due, so you don’t have to think about it.'}
            </p>
          </div>
        }
        rightContent={
          <BeamButton
            label={
              enabledAutopayBeamFees && !enabledAutopayNonprofits
                ? 'Set Up Autopay For Donations'
                : 'Set up Autopay for Beam Fees'
            }
            variant="basic_blue"
            onClick={() =>
              onClickAutopayCTA(stripeData.data?.autopay, user?.partner?.ppgfStatus, setActiveModal)
            }
          />
        }
      />
    </div>
  )

  return (
    <PaymentsSetupModalsContext.Provider
      value={{
        activeModal,
        changeActiveModal,
      }}>
      <BeamSEO title="Overview" />

      <PaymentsSetupModals
        activeModal={activeModal}
        afterModalCloseHandler={afterModalCloseHandler}
        invoiceEmails={invoiceEmails}
        isPaypalSetup={user?.partner?.ppgfStatus === PaypalGivingFundStatusEnums.enrolled}
        partnerId={user?.partnerId}
      />

      <BeamCTA
        canClose={true}
        canCloseUniqueKey="referral"
        backgroundColor="--beam-color--sky-500"
        leftContent={
          <div>
            <h3 className="m-0 text-white">
              <span className="inline-block mr-2 align-middle">
                <Prize fill="FFFFFF" />
              </span>
              <span className="inline-block align-middle">You Refer, We Reward!</span>
            </h3>
            <p className="text-white beam--paragraph--small">
              Earn either 1 free month of Beam or a $1,000 Visa Gift card for every eligible brand
              you successfully refer!
            </p>
          </div>
        }
        rightContent={
          <Link to={`/contact-support?refill=referral`}>
            <BeamButton
              label={
                <>
                  Get Started
                  <RightArrow className="inline-block ml-2" />
                </>
              }
              variant="borderless_white"
            />
          </Link>
        }
      />

      {!!user.canSeeCumulativeImpactCTA && (
        <>
          <br />
          <BeamMiniCTA
            backgroundColor={'--beam-color--coral-50'}
            title="2023 Impact Wrapped"
            description={
              !hasPartnerRequestedCumulativeImpactDataForThisYear
                ? 'This Giving Tuesday, share your 2023 Impact with your customers. Request your Impact Wrapped by the 11/16 and we’ll share the tangible outcomes you’ve funded in 2023 in 11/20'
                : 'Your request was sent for your 2023 Impact Wrapped! You will receive it by email on November 20th with a template to tailor social assets to share with your followers'
            }
            icon={<RainbowIcon />}
            buttonLabel={
              !hasPartnerRequestedCumulativeImpactDataForThisYear
                ? 'Request Your 2023 Impact Wrapped'
                : '2023 Impact Wrapped Requested'
            }
            disabled={hasPartnerRequestedCumulativeImpactDataForThisYear}
            variant="mini"
            buttonVariant={
              !hasPartnerRequestedCumulativeImpactDataForThisYear ? 'white' : 'neutral'
            }
            onClick={async () => {
              const response = await holidayCumulativeImpactDataPartnerRequest(partnerId)

              setStatusFromHolidayCumulativeImpactRequest(response.ok)
              setShowHolidayCumulativeImpactRequestModal(true)
            }}
          />
          <br />
          <hr className={$$.hrGivingTuesday} />
          <br />
        </>
      )}

      <OverviewNotifications
        autopayCTAComponent={AutopayCTAComponent}
        shouldDisplayAutopayCTA={shouldDisplayAutopayCTA}
        promos={user.upcomingPromos}
      />

      <div className="grid grid-cols-1">
        {!isNil(currentSiteFilter?.storeId) && (
          <h1 className="beam--heading--1">{currentSiteFilter?.name} Overview</h1>
        )}
        {isNil(currentSiteFilter?.storeId) && (
          <h1 className="beam--heading--1">&#128075; Hello, Here&apos;s your Beam Overview!</h1>
        )}

        <p className={$$.tagLine}>
          Here&apos;s how Beam is performing for {user.partner.name}. Have any questions?{' '}
          <Link to={`/contact-support`}>Contact Support</Link>.
        </p>
      </div>

      {calculatedData?.data?.optimalMetrics?.roiModuleState === ROIModuleState.Show && (
        <BusinessImpactModule
          calculatedData={calculatedData.data.optimalMetrics}
          loading={calculatedData.loading}
        />
      )}

      {calculatedData?.data?.optimalMetrics?.roiModuleState === ROIModuleState.SiteSpecific && (
        <div className={cx('px-8 py-6 bg-lime-50', $$.siteIndicatorForRoiModule)}>
          ROI Highlights are not available in Partner Portal for individual sites. Contact your
          Client Strategy Lead if you need to access this data.
        </div>
      )}

      <MonthlyCustomerEngagementSection
        calculatedData={calculatedData?.data?.supplementaryMetrics ?? null}
        loading={calculatedData.loading}
      />
      <CumulativeSocialImpactSection
        data={calculatedData.data?.supplementaryMetrics}
        loading={calculatedData.loading}
      />
      <div className="grid grid-cols-1 mb-4 space-x-0 space-y-4 desktop:space-y-4 desktop:mt-4 desktop:space-x-4 desktop:grid-cols-2">
        <ImpactFromActiveNonprofits
          impactData={cumulativeImpactData.data}
          loading={impactData.loading}
        />
        <ActiveNonprofitPartnersBlock impact={impactData.data} loading={impactData.loading} />
      </div>
      <BeamToast
        onClose={() => setOpenToast(false)}
        open={openToast}
        closable={true}
        text={'Autopay is now set up!'}
        variant={'success'}
        icon={<span>&#127881;</span>}
      />

      {!!showHolidayCumulativeImpactRequestModal && (
        <BeamToast
          onClose={() => setShowHolidayCumulativeImpactRequestModal(false)}
          open={showHolidayCumulativeImpactRequestModal}
          closable={true}
          duration={5000}
          text={
            !statusFromHolidayCumulativeImpactRequest
              ? 'There was an error with your request.  Please try again.'
              : 'Request Sent! Your 2023 Impact Recap will be emailed on November 20'
          }
          variant={!statusFromHolidayCumulativeImpactRequest ? 'error' : 'success'}
          icon={!statusFromHolidayCumulativeImpactRequest ? null : <span>&#127881;</span>}
        />
      )}

      <InternalDevPanel
        data={[
          {
            label: 'chainId',
            value: user?.chainId,
          },
          {
            label: 'partnerId',
            value: user?.partnerId,
          },
          {
            label: 'reportPeriodId',
            value: calculatedData.data?.optimalMetrics.reportPeriodId,
          },
          {
            label: 'Feature Flags',
            value: user?.featureFlags
              ?.map((f: any) => f.key)
              .sort()
              .join(' | '),
          },
        ]}
      />
    </PaymentsSetupModalsContext.Provider>
  )
}
