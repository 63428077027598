import { LocationStateProps } from '../PrivateRoute'

export enum OnboardingParamKeys {
  ONBOARDING_STEP = 'onboarding_step',
  REQUEST_ID = 'shopify_onboarding_request_id',
}

export enum OnboardingStepParamValues {
  AWAITING_APPROVAL = 'awaiting_approval',
  CONNECT = 'connect',
  CONTACT = 'contact',
  EXISTING_CHAIN_AWAITING_APPROVAL = 'existing_chain_awaiting_approval',
  PRIVATE_APP_UPGRADE_AWAITING_APPROVAL = 'private_app_upgrade_awaiting_approval',
  PRIVATE_APP_UPGRADE_BEGIN = 'private_app_upgrade_begin',
}

export interface LocationShopifyStates extends LocationStateProps {
  onboardingStep: OnboardingStepParamValues | undefined
  shopifyOnboardingRequestId?: string
}
