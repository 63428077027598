import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { associateSalesforceAccountWithPartner } from './SalesforceOathCallbackPage.api'

const SALESFORCE_OAUTH_PARAM_KEY = 'code'
const loadingStr = 'Linking your Salesforce organization. Do not navigate away from this page.'
const successStr =
  'Successfully linked your Salesforce organization 🎉. You may now close this page.'

async function getSalesforceOauthCodeAndAssociateWithPartner() {
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  const codeParam = urlParams.get(SALESFORCE_OAUTH_PARAM_KEY)
  // clear out code to prevent user from refreshing/sending multiple requests
  window.history.replaceState('', '', '/')
  await associateSalesforceAccountWithPartner({ code: codeParam })
}

export const SalesforceOauthCallbackPage = () => {
  const [isLoading, setIsLoading] = useState(true)
  const history = useHistory()

  useEffect(() => {
    const init = async () => {
      try {
        await getSalesforceOauthCodeAndAssociateWithPartner()
        setIsLoading(false)
      } catch (e) {
        history.replace(`/salesforce/error`)
      }
    }
    init()
  }, [history])

  const headerStr = isLoading ? loadingStr : successStr
  return (
    <div className="pt-6">
      <h2>{headerStr}</h2>
    </div>
  )
}
