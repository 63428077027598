export const XIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.95074 8.00823L12.4794 4.47957C12.5431 4.41807 12.5939 4.34451 12.6288 4.26317C12.6637 4.18183 12.6821 4.09435 12.6829 4.00583C12.6837 3.91731 12.6668 3.82953 12.6333 3.7476C12.5998 3.66567 12.5503 3.59123 12.4877 3.52864C12.4251 3.46604 12.3506 3.41654 12.2687 3.38302C12.1868 3.3495 12.099 3.33263 12.0105 3.3334C11.922 3.33417 11.8345 3.35256 11.7531 3.3875C11.6718 3.42244 11.5982 3.47323 11.5367 3.5369L8.00807 7.06557L4.47941 3.5369C4.35367 3.41546 4.18527 3.34826 4.01047 3.34978C3.83567 3.3513 3.66847 3.42142 3.54486 3.54502C3.42125 3.66863 3.35114 3.83583 3.34962 4.01063C3.3481 4.18543 3.4153 4.35383 3.53674 4.47957L7.06541 8.00823L3.53674 11.5369C3.47307 11.5984 3.42228 11.672 3.38734 11.7533C3.3524 11.8346 3.33401 11.9221 3.33324 12.0106C3.33247 12.0992 3.34934 12.1869 3.38286 12.2689C3.41638 12.3508 3.46588 12.4252 3.52848 12.4878C3.59107 12.5504 3.66551 12.5999 3.74744 12.6334C3.82937 12.667 3.91715 12.6838 4.00567 12.6831C4.09419 12.6823 4.18167 12.6639 4.26301 12.629C4.34434 12.594 4.41791 12.5432 4.47941 12.4796L8.00807 8.9509L11.5367 12.4796C11.6625 12.601 11.8309 12.6682 12.0057 12.6667C12.1805 12.6652 12.3477 12.5951 12.4713 12.4714C12.5949 12.3478 12.665 12.1806 12.6665 12.0058C12.668 11.831 12.6008 11.6626 12.4794 11.5369L8.95074 8.00823Z"
        fill="#FAFAFA"
      />
    </svg>
  )
}
