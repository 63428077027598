import { Redirect } from 'react-router-dom'

import { OnboardingStepParamValues } from './types'

interface ShopifyRoutesDispatcherProps {
  locationState: Record<any, any>
  onboardingStep: OnboardingStepParamValues | undefined
}

export const ShopifyRoutesDispatcher = ({
  locationState,
  onboardingStep,
}: ShopifyRoutesDispatcherProps) => {
  const newLocationState = { ...locationState, onboardingStep }

  switch (onboardingStep) {
    case OnboardingStepParamValues.PRIVATE_APP_UPGRADE_BEGIN:
    case OnboardingStepParamValues.PRIVATE_APP_UPGRADE_AWAITING_APPROVAL:
      return (
        <Redirect
          to={{
            pathname: `/shopify-public-app/upgrade`,
            state: newLocationState,
          }}
        />
      )
    case OnboardingStepParamValues.CONNECT:
      return (
        <Redirect
          to={{
            pathname: `/shopify-public-app/connect`,
            state: newLocationState,
          }}
        />
      )
    case OnboardingStepParamValues.CONTACT:
    case OnboardingStepParamValues.AWAITING_APPROVAL:
    case OnboardingStepParamValues.EXISTING_CHAIN_AWAITING_APPROVAL:
      return (
        <Redirect
          to={{
            pathname: `/shopify-public-app/associate`,
            state: newLocationState,
          }}
        />
      )
    default:
      return (
        <Redirect
          to={{
            pathname: `/`,
            state: newLocationState,
          }}
        />
      )
  }
}
