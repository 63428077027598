import { SlTooltip } from '@shoelace-style/shoelace/dist/react'
import cx from 'classnames'
import { ReactNode } from 'react'

import { BeamDSProps } from '../interface'
import $$ from './beam-tooltip.module.css'

interface BeamTooltipProps extends BeamDSProps {
  /**
   * Content that shows up in tooltip
   */
  content: string | ReactNode
  /**
   * Content to target tooltip
   */
  children: string | ReactNode
  /**
   * Placement of tooltip
   */
  placement?:
    | 'top'
    | 'top-start'
    | 'top-end'
    | 'right'
    | 'right-start'
    | 'right-end'
    | 'bottom'
    | 'bottom-start'
    | 'bottom-end'
    | 'left'
    | 'left-start'
    | 'left-end'
  /**
   * Hoist the tooltip using a fixed positioning strategy, allowing the tooltip to overlay its parent component
   */
  hoist?: boolean
}

/**
 * Tooltip component
 */
export const BeamTooltip = ({ content, children, hoist = true, ...props }: BeamTooltipProps) => {
  return (
    <SlTooltip {...props} className={cx($$.tooltip, props.className, 'beam--tooltip')} hoist>
      <div slot="content">{content}</div>
      {children}
    </SlTooltip>
  )
}
