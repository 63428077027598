import { isNil } from 'lodash'
import { ChangeEvent, Dispatch, ReactNode, SetStateAction, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

import { getV2ApiUrl } from '../../../api/auth'
import { useBeamSelector } from '../../../hooks'
import { useUrlSearchParams } from '../../../hooks/useUrlSearchParams'
import { BeamButton } from '../../../stories/BeamButton'
import { BeamCard } from '../../../stories/BeamCard'
import { BeamCheckbox } from '../../../stories/BeamCheckbox/BeamCheckbox'
import { BeamLogo } from '../../../stories/BeamLogo'
import { BeamTextfield } from '../../../stories/BeamTextfield'
import { BeamToast } from '../../../stories/BeamToast'
import { axiosRequest } from '../../../utils/axiosRequest'
import { isAuthenticated } from '../../../utils/helpers/isAuthenticated'
import { TUser } from '../../../utils/types'
import { ShopifyRoutesDispatcher } from './ShopifyRoutesDispatcher'
import { LocationShopifyStates, OnboardingParamKeys, OnboardingStepParamValues } from './types'

const FormContainer = ({ children }: { children: ReactNode }) => {
  return (
    <div
      className={`w-full h-full beam-bg--gradient-coral fixed top-0 left-0 z-[500] flex flex-col items-center justify-center`}>
      <div className={'w-3/4 desktop:w-1/2'}>
        <BeamCard backgroundColor={'--beam-color--white'}>
          <div
            className={
              'flex flex-col justify-center items-center m-auto pb-[32px] w-[90%] desktop:w-[60%] desktop:pb-[84px]'
            }>
            <div className={'mb-4 pt-[32px] desktop:pt-[84px] mx-auto'}>
              <BeamLogo />
            </div>
            {children}
          </div>
        </BeamCard>
      </div>
    </div>
  )
}

const StatusMessage = ({ children }: { children: ReactNode }) => {
  return (
    <FormContainer>
      <div className={'py-[200px]'}>
        <h2 className={'font-secondary font-bold m-0 text-center'}>{children}</h2>
      </div>
    </FormContainer>
  )
}

interface ContactInfo {
  name: string
  email: string
  phone: string
  tosAccepted: boolean
}
interface OnboardingRequestBody {
  shopifyOnboardingRequestId: string
  contact: {
    email?: string
    phone?: string
    name: string
  }
}

interface AddOnboardingRequestApiResponse {
  message?: string
  shopifyOnboardingRequest?: {
    id?: string
    email?: string
  }
}

type FormStatus = {
  status: 'submitted' | 'error'
  errorMessage?: string
  responseData?: AddOnboardingRequestApiResponse
} | null

export const ShopifyInstallPage = () => {
  const location = useLocation<LocationShopifyStates>()
  const user: TUser | undefined = useBeamSelector(({ user }) => user)
  const [formStatus, setFormStatus] = useState<FormStatus>(null)
  const { queryParams } = useUrlSearchParams()
  const onboardingStep = queryParams.get(OnboardingParamKeys.ONBOARDING_STEP) as
    | OnboardingStepParamValues
    | undefined
  const requestId = queryParams.get(OnboardingParamKeys.REQUEST_ID) || ''
  const newState = { ...location.state, shopifyOnboardingRequestId: requestId }

  if (isAuthenticated(user) && user?.partner?.name) {
    return (
      <ShopifyRoutesDispatcher locationState={{ ...newState }} onboardingStep={onboardingStep} />
    )
  }

  if (onboardingStep === OnboardingStepParamValues.AWAITING_APPROVAL) {
    return <StatusMessage>Beam Customer Support will contact you shortly.</StatusMessage>
  }

  if (formStatus?.status === 'submitted') {
    return (
      <StatusMessage>
        Thank you for your interest in Beam. Beam Customer Support will contact{' '}
        {formStatus.responseData?.shopifyOnboardingRequest?.email || 'you'} shortly.
      </StatusMessage>
    )
  }

  return (
    <ContactForm
      setFormStatus={setFormStatus}
      formStatus={formStatus}
      onboardingStep={onboardingStep}
    />
  )
}

const ContactForm = ({
  setFormStatus,
  onboardingStep,
  formStatus,
}: {
  setFormStatus: Dispatch<SetStateAction<FormStatus>>
  onboardingStep: OnboardingStepParamValues | undefined
  formStatus: FormStatus
}) => {
  const [contactInfo, setContactInfo] = useState<ContactInfo>({
    name: '',
    email: '',
    phone: '',
    tosAccepted: false,
  })
  const { queryParams } = useUrlSearchParams()
  const requestId = queryParams.get(OnboardingParamKeys.REQUEST_ID)

  function handleOnChange(e: ChangeEvent<HTMLInputElement>) {
    if (e.target.name === 'tosAccepted') {
      setContactInfo(prev => ({ ...prev, tosAccepted: !prev.tosAccepted }))
      return
    }

    setContactInfo(prev => ({ ...prev, [e.target.name]: e.target.value }))
  }

  async function handleSubmit(e: any) {
    e.preventDefault()

    try {
      const requestBody: OnboardingRequestBody = {
        shopifyOnboardingRequestId: requestId || '',
        contact: contactInfo,
      }
      const res = await axiosRequest(
        'POST',
        `${getV2ApiUrl()}/shopify-public-app/add-onboarding-request-contact`,
        requestBody
      )

      const data: AddOnboardingRequestApiResponse = res.data

      setFormStatus({
        status: 'submitted',
        errorMessage: undefined,
        responseData: data,
      })
    } catch (error: any) {
      setFormStatus({
        status: 'error',
        errorMessage: 'There was a problem submitting your information. Please try again.',
      })
    }
  }

  const allInputsFilled = Object.values(contactInfo).every(val => Boolean(val))

  return (
    <FormContainer>
      <BeamToast
        duration={Infinity}
        variant={'error'}
        open={!isNil(formStatus) && formStatus.status === 'error'}
        text={formStatus?.errorMessage || ''}
        onClose={() => {
          setFormStatus(null)
        }}
      />
      <form onSubmit={handleSubmit}>
        <div className={'space-y-2'}>
          <BeamTextfield
            name={'name'}
            label={'Name'}
            variant={'small'}
            type={'text'}
            onChange={handleOnChange}
            required
          />
          <BeamTextfield
            name={'email'}
            label={'Email'}
            type={'email'}
            variant={'small'}
            onChange={handleOnChange}
            required
          />
          <BeamTextfield
            name={'phone'}
            label={'Phone Number'}
            variant={'small'}
            type={'tel'}
            onChange={handleOnChange}
            required
          />
        </div>
        <div className={'pt-6'}>
          <BeamCheckbox
            name={'tosAccepted'}
            checked={contactInfo.tosAccepted}
            onChange={handleOnChange}>
            <span className={'text-sm '}>
              By checking this box, I acknowledge that I have read, understood, and agree to Beam
              Impact&apos;s{' '}
              <a
                href={'https://www.beamimpact.com/terms'}
                target={'_blank'}
                rel={'noopener noreferrer'}>
                Terms of Service
              </a>{' '}
              and{' '}
              <a
                href={'https://www.beamimpact.com/privacy-policy'}
                target={'_blank'}
                rel={'noopener noreferrer'}>
                Privacy Policy
              </a>
              .
            </span>
          </BeamCheckbox>
        </div>
        <div className={'flex justify-center w-full pt-6'}>
          <BeamButton
            type={'submit'}
            variant={'elevated'}
            label={'Submit'}
            className={'w-full'}
            disabled={!allInputsFilled}
          />
        </div>
        <div className={'py-4 font-normal text-md text-sky-800'}>
          <em>* required</em>
        </div>
        <div className={'text-center text-sm font-normal'}>
          Already have an account?{' '}
          <Link
            to={{
              pathname: `/login`,
              state: {
                onboardingStep,
                shopifyOnboardingRequestId: requestId,
                from: {
                  pathname: window.location.pathname,
                },
              },
            }}>
            Sign In
          </Link>
        </div>
      </form>
    </FormContainer>
  )
}
