import cx from 'classnames'
import { round } from 'lodash'
import { useContext } from 'react'
import { useHistory } from 'react-router'

import { useBeamSelector } from '../../../../hooks'
import { BeamButton } from '../../../../stories/BeamButton'
import { BeamDataPointBlock } from '../../../../stories/BeamDataPointBlock'
import { BeamTooltip } from '../../../../stories/BeamTooltip'
import { dollarFormat, percentFormat } from '../../../../utils/root'
import { TUser } from '../../../../utils/types'
import { MoneyBagIcon } from '../../../root/iconComponents/MoneyBagIcon'
import { dataWikiUrl } from '../../../root/Root'
import { TitleWithTooltip } from '../../../root/TitleWithTooltip'
import { ReactComponent as GraphIcon } from '../assets/graphIcon.svg'
import { ReactComponent as InfoIcon } from '../assets/infoIcon.svg'
import { ReactComponent as UpwardTrendIcon } from '../assets/upwardTrendIcon.svg'
import $$ from '../overview-page.module.css'
import { DataWikiObject, RoiMetricsObject } from '../OverviewPage.types'
import { TimeframeToggleContext } from '../TimeframeToggleProvider'
import {
  getHasBrandSpecificConversionPercent,
  getVisibleSubscriptionMetricsCount,
  shouldWeDisplaySubscriptionAOF,
  shouldWeDisplaySubscriptionAOV,
  shouldWeDisplaySubscriptionSignupLift,
} from './BusinessImpactModule.helpers'

export const SubscriptionPurchaseDataSection = ({
  oneMonthMetrics,
  sixMonthMetrics,
  dataWikiData,
}: {
  oneMonthMetrics: RoiMetricsObject
  sixMonthMetrics: RoiMetricsObject
  dataWikiData: DataWikiObject
}) => {
  const { timeframeView, setTimeframeView } = useContext(TimeframeToggleContext)
  const history = useHistory()
  const user: TUser | undefined = useBeamSelector(({ user }) => user)

  const inSixMonthView = timeframeView === 'sixMonth'
  const metricsData = inSixMonthView ? sixMonthMetrics : oneMonthMetrics
  const numberOfVisibleSubscriptionMetrics = getVisibleSubscriptionMetricsCount(metricsData)
  const shouldShowSubscriptionPurchaseSection = numberOfVisibleSubscriptionMetrics > 0
  const hasBrandSpecificConversionPercent = getHasBrandSpecificConversionPercent(dataWikiData)

  if (!shouldShowSubscriptionPurchaseSection) return null

  return (
    <div>
      {
        <h3 className={'max-w-fit'}>
          <BeamTooltip
            content={
              'This includes all orders made by first time or returning customers (and excludes any subscription orders if applicable)'
            }>
            <span className={'text-md font-medium items-center flex'}>
              Subscription Order Data <InfoIcon className={'inline ml-1'} />
            </span>
          </BeamTooltip>
        </h3>
      }
      <div
        className={cx(
          $$.metricsBlocksGrid,
          `grid grid-cols-1 space-x-0 space-y-4 desktop:space-y-0 desktop:space-x-4`,
          {
            'desktop:grid-cols-2': numberOfVisibleSubscriptionMetrics <= 2,
            'desktop:grid-cols-3': numberOfVisibleSubscriptionMetrics > 2,
          }
        )}>
        {shouldWeDisplaySubscriptionAOV(metricsData) && (
          <div className={`${$$.block}`}>
            <BeamDataPointBlock
              title={
                <TitleWithTooltip
                  title="Average Order Value Lift"
                  tooltipContent={`The difference between average dollars spent per transaction, within the report period date range, between transactions that didn't include a redemption and transactions that included a nonprofit selection`}
                />
              }
              datum={`+${percentFormat(metricsData.subscriptionAverageOrderValueLift, null, 1)}`}
              description={
                <span className={`iconOverrideOverviewPage ${$$.roiMetricComparisonSubheading}`}>
                  <UpwardTrendIcon /> ({dollarFormat(oneMonthMetrics.nonBeamSubscriptionAov, 2)}{' '}
                  without Beam vs {dollarFormat(oneMonthMetrics.beamSubscriptionAov, 2)} with Beam)
                </span>
              }
              icon={<MoneyBagIcon />}
              backgroundColor={'--beam-color--lime-50'}
            />
          </div>
        )}

        {shouldWeDisplaySubscriptionAOF(metricsData) && (
          <div className={`${$$.block}`}>
            <BeamDataPointBlock
              title={
                <TitleWithTooltip
                  title="Average Order Frequency Lift"
                  tooltipContent={`The difference between the average number of transactions per unique customer where a donation was not redeemed and the average number of transactions per unique customer where a donation was redeemed`}
                />
              }
              datum={`+${percentFormat(
                metricsData.subscriptionAverageOrderFrequencyLift,
                null,
                1
              )}`}
              description={
                <span className={`iconOverrideOverviewPage ${$$.roiMetricComparisonSubheading}`}>
                  <UpwardTrendIcon />(
                  {metricsData.nonBeamSubscriptionOrderFrequency?.toFixed(2) || 'N/A'} without Beam
                  vs {metricsData.beamSubscriptionOrderFrequency?.toFixed(2) || 'N/A'} With Beam)
                </span>
              }
              icon={<GraphIcon />}
              backgroundColor={'--beam-color--lime-50'}
            />
          </div>
        )}

        {shouldWeDisplaySubscriptionSignupLift(metricsData) && (
          <div className={`${$$.block}`}>
            <BeamDataPointBlock
              title={
                <TitleWithTooltip
                  className={'subscriberSignupLiftTooltip'}
                  title="Subscriber Signup Lift"
                  tooltipContent={
                    <>
                      <div className={'mb-4'}>
                        A measurement of the relative lift in subscriber conversion between
                        customers who selected a nonprofit when they made a purchase and the number
                        who added to cart without engaging with the Beam integration to find the
                        incremental new subscriptions attributed to Beam. To account for any
                        selective engagement bias, we discount that number by multiplying by{' '}
                        {hasBrandSpecificConversionPercent
                          ? `a ${user?.partner.name} AB Test discount factor: ${percentFormat(
                              dataWikiData.abTestDiscountFactor,
                              null,
                              0
                            )}`
                          : `an industry standard AB Test discount factor: ${percentFormat(
                              dataWikiData.estimatedGlobalAbTestDiscountFactor,
                              null,
                              0
                            )}`}
                        .
                      </div>

                      <BeamButton
                        variant={'flat_white'}
                        label={'Go to Calculation Page'}
                        size={'small'}
                        onClick={() => history.push(dataWikiUrl)}
                        className={'w-min'}
                      />
                    </>
                  }
                />
              }
              datum={`+${percentFormat(metricsData.subscriptionSignUpLift, null, 1, false, true)}`}
              description={
                inSixMonthView ? (
                  <span className={`iconOverrideOverviewPage ${$$.roiMetricComparisonSubheading}`}>
                    <UpwardTrendIcon />(
                    {metricsData.totalSubscriptionFirstOrders
                      ? round(metricsData.totalSubscriptionFirstOrders)
                      : 'N/A'}{' '}
                    Beam vs{' '}
                    {metricsData.baselineFirstSubscriptions
                      ? round(metricsData.baselineFirstSubscriptions)
                      : 'N/A'}{' '}
                    Baseline)
                  </span>
                ) : (
                  <div className={'flex flex-col items-start justify-start'}>
                    <span className={$$.roiMetricSubheading__plain}>
                      Not included in sales lift for 1 Month View
                    </span>
                    <BeamButton
                      variant={'text'}
                      label={'See Estimated 6 Month Sales Lift'}
                      className={$$.roiMetricSubheading__plain__button}
                      size={'small'}
                      onClick={() => setTimeframeView('sixMonth')}
                    />
                  </div>
                )
              }
              icon={<GraphIcon />}
              backgroundColor={inSixMonthView ? '--beam-color--lime-50' : '--beam-color--lime-200'}
              backgroundPattern={inSixMonthView ? 'solid' : 'striped'}
            />
          </div>
        )}
      </div>
    </div>
  )
}
