import { API_BASE_PATH_PORTAL } from '../../../api/auth'
import { axiosRequest } from '../../../utils/axiosRequest'

function getPartnerPortalAppBasePath() {
  return window.location.origin
}

export async function getSalesforceUrl() {
  const partnerPortalAppBasePath = getPartnerPortalAppBasePath()
  const response = await axiosRequest(
    'post',
    `${API_BASE_PATH_PORTAL}/salesforce-oms/get-authorize-url`,
    {
      redirectUrl: `${partnerPortalAppBasePath}/salesforce/authorize-callback`,
    }
  )
  return response.data
}
