import { useEffect, useState } from 'react'
import { Link, Redirect } from 'react-router-dom'

import { getV2ApiUrl } from '../../../api/auth'
import { BeamLoadingIndicator } from '../../../stories/BeamLoadingIndicator'
import { axiosRequest } from '../../../utils/axiosRequest'
import { CenteredContent } from '../CenteredContent'

export const ShopifyAppConnectPage = () => {
  const [connected, setConnected] = useState(false)
  const [error, setError] = useState('')

  useEffect(() => {
    ;(async () => {
      try {
        await axiosRequest('POST', `${getV2ApiUrl()}/shopify-public-app/connect`)
        setConnected(true)
      } catch (error: any) {
        console.error(error)
        setError(error.message)
      }
    })()
  }, [])

  if (error) {
    return (
      <CenteredContent inLayoutContainer>
        <h2>There was an error connecting your app</h2>
        <div className={'p-4 bg-cherry-50 rounded'}>
          <span className={'font-bold'}>Reference Error:</span> {error}
        </div>
        <Link className={'pt-4'} to={`/`}>
          Go Home
        </Link>
      </CenteredContent>
    )
  }

  if (connected) {
    return <Redirect to={`/`} />
  }

  return (
    <CenteredContent inLayoutContainer>
      <BeamLoadingIndicator />
    </CenteredContent>
  )
}
