import cx from 'classnames'
import { isNil, round } from 'lodash'
import { useEffect, useState } from 'react'

import { useBeamSelector } from '../../../hooks'
import { dollarFormat } from '../../../utils/root'
import { Maybe, TSite, TUser } from '../../../utils/types'
import { UpDownIcon } from '../../root/iconComponents/UpDown'
import {
  getHasBrandSpecificConversionPercent,
  shouldDisplayAnyOneTimePurchaseMetrics,
  shouldDisplayAnySubscriptionMetrics,
} from '../OverviewPage/BusinessImpactModule/BusinessImpactModule.helpers'
import { fetchOptimalRoiWithSubscriptions } from '../OverviewPage/Overview.api'
import { OptimalRoiWithSubscriptionsResponse } from '../OverviewPage/OverviewPage.types'
import { BeamMetricInputBox, MetricInputTypes } from './BeamMetricInputBox/BeamMetricInputBox'
import { CalculationBox } from './CalculationBox'
import { CalculationTable } from './CalculationTable/CalculationTable'
import $$ from './data-wiki.module.css'
import { calculateSixMonthSalesLift } from './DataWikiPage.helpers'

const SIX_MONTH_DURATION_VALUE = 6

interface RoiResponseWithLoadingState {
  data: OptimalRoiWithSubscriptionsResponse | null
  loading: boolean
  error: string | null
}

function toPercent({
  input,
  defaultValue = 0,
  precision = 0,
}: {
  input: Maybe<number>
  defaultValue?: number
  precision?: number
}) {
  if (isNil(input)) {
    return `${round(defaultValue, precision)}%`
  }

  const pctValue = input * 100
  return `${round(pctValue, precision)}%`
}

export const DataWikiPage = () => {
  const [roiResponse, setRoiResponse] = useState<RoiResponseWithLoadingState>({
    data: null,
    loading: true,
    error: null,
  })
  const user: TUser | undefined = useBeamSelector(({ user }) => user)
  const site: TSite | undefined = useBeamSelector(({ site }) => site)
  const oneMonthMetrics = roiResponse.data?.oneMonthMetrics.optimalMetrics || null
  const dataWikiData = roiResponse.data?.dataWiki || null
  const hasBrandSpecificConversionPercent = getHasBrandSpecificConversionPercent(dataWikiData)
  const sslCopy = hasBrandSpecificConversionPercent
    ? `To account for any selective bias, we discount the relative lift in conversion we've calculated by a ${user?.partner?.name} specific A/B test discount factor. We calculate this by taking the A/B Test Cohort CCR Lift - which is calculated by dividing redemption rate during the A/B test by the % customers exposed to Beam in the test - and dividing by Subscriber Signup Lift during the A/B test`
    : `To account for any selective bias, we discount the relative lift in conversion we've calculated by an industry standard A/B test discount factor. We calculate this by taking the A/B Test Cohort CCR Lift - which is calculated by dividing redemption rate during the A/B test by the % customers exposed to Beam in the test - and dividing by Subscriber Signup Lift and averaging across A/B tests`
  const isSubscriptionOnly: boolean =
    !isNil(oneMonthMetrics) &&
    shouldDisplayAnySubscriptionMetrics(oneMonthMetrics) &&
    !shouldDisplayAnyOneTimePurchaseMetrics(oneMonthMetrics)

  useEffect(() => {
    if (!user?.partnerId) {
      return
    }

    setRoiResponse(prev => ({
      data: prev.data,
      loading: true,
      error: prev.error,
    }))
    ;(async () => {
      try {
        const response = await fetchOptimalRoiWithSubscriptions(
          user?.partnerId,
          site?.storeId || undefined
        )
        setRoiResponse({
          data: response,
          loading: false,
          error: null,
        })
      } catch (error: any) {
        console.error(error)
        setRoiResponse({
          data: null,
          loading: false,
          error: 'There was an error fetching your ROI data.',
        })
      }
    })()
  }, [site?.storeId, user?.partnerId])

  return (
    <article className={cx($$.dataWikiPageContainer, 'pt-14')}>
      <div className={cx($$.paragraphBlock, 'mb-11')}>
        <h1 className={'m-0 mb-2'}>Subscription Data Dictionary</h1>
        <p>
          This page outlines how we calculate the incremental new subscriptions the Beam integration
          has driven and the sales lift this would drive in a 6 month period, taking into account
          expected subscriber churn.
        </p>
      </div>
      <h2>Subscriber Signup Lift</h2>
      <div className={cx($$.paragraphBlockGroup)}>
        <div className={$$.paragraphBlock}>
          <h3>What is Subscriber Sales Lift? </h3>
          <p>
            Subscriber Signup Lift measures the relative lift between conversion rates of one-time
            orders to become a new subscription where there was a nonprofit selection and new
            subscriptions where there was no nonprofit selection to find the number of incremental
            new subscriptions that have been driven by Beam.
          </p>
        </div>

        <div className={$$.paragraphBlock}>
          <h3>How do we calculate it? </h3>
          <p>
            To calculate we measure the relative lift in subscriber conversion between customers who
            selected a nonprofit when they made a purchase and the number who added to cart without
            engaging with the Beam integration. To account for any selective engagement bias, we
            then discount that number by an{' '}
            {hasBrandSpecificConversionPercent
              ? 'AB Test adjustment factor that is specific to your brand'
              : 'industry standard AB Test adjustment factor'}
            .
          </p>
        </div>
      </div>
      <div className={'mt-7'}>
        <BeamMetricInputBox
          loading={roiResponse.loading}
          data={[
            { type: MetricInputTypes.header, label: 'From Your Monthly ROI Highlight' },
            {
              type: MetricInputTypes.metric,
              label: 'Beam First Subscription CCR',
              value: toPercent({ input: dataWikiData?.beamFirstSubscriptionCcr, precision: 2 }),
              tooltip: `Orders with an in-cart nonprofit selection ID that are tagged as First Time Subscription Orders divided by Beam carts (the total number of carts with 1 or more items generated for this time period with a nonprofit selection ID)`,
              icon: <UpDownIcon />,
            },
            {
              type: MetricInputTypes.metric,
              label: 'Non-Beam First Subscription CCR',
              value: toPercent({ input: dataWikiData?.nonBeamFirstSubscriptionCcr, precision: 2 }),
              tooltip: `First time subscription orders with no nonprofit selection divided by the total number of non-Beam carts (calculated by deducting carts with a nonprofit selection from total carts created)`,
              icon: <UpDownIcon />,
            },
            {
              type: MetricInputTypes.header,
              label: hasBrandSpecificConversionPercent
                ? `From ${user?.partner.name}`
                : 'Industry Benchmark',
            },
            {
              type: MetricInputTypes.metric,
              label: 'AB Test Discount Factor',
              value: hasBrandSpecificConversionPercent
                ? toPercent({ input: dataWikiData?.abTestDiscountFactor })
                : toPercent({ input: dataWikiData?.estimatedGlobalAbTestDiscountFactor }),
              tooltip: sslCopy,
            },
          ]}
        />
      </div>
      <div className={'mt-7 pb-4'}>
        <CalculationBox
          loading={roiResponse.loading}
          operands={[
            {
              value:
                '( ' +
                toPercent({ input: oneMonthMetrics?.beamFirstSubscriptionCcr, precision: 2 }),
              description: 'Beam Subscription CCR',
            },
            {
              value:
                toPercent({ input: oneMonthMetrics?.nonBeamFirstSubscriptionCcr, precision: 2 }) +
                ' )',
              description: 'Non-Beam Subscription CCR',
            },
            {
              value: toPercent({
                input: oneMonthMetrics?.nonBeamFirstSubscriptionCcr,
                precision: 2,
              }),
              description: 'Non-Beam Subscription CCR',
            },
            {
              value:
                '( 1 - ' +
                (hasBrandSpecificConversionPercent
                  ? toPercent({ input: dataWikiData?.abTestDiscountFactor })
                  : toPercent({ input: dataWikiData?.estimatedGlobalAbTestDiscountFactor })) +
                ' )',
              description: 'AB Test Discount Factor',
            },
            {
              value: toPercent({
                input: oneMonthMetrics?.subscriptionSignUpLift || 0,
                precision: 2,
              }),
              description: 'Subscriber \n' + 'Signup Lift',
            },
          ]}
          operators={['-', '/', 'X', '=']}
        />
      </div>
      <h2>Incremental First Subscriptions Attributed to Beam</h2>
      <div className={$$.paragraphBlockGroup}>
        <div className={$$.paragraphBlock}>
          <h3>What is Incremental First Subscriptions Attributed to Beam? </h3>
          <p>
            Incremental Subscriptions is the number of one-time purchases that are converted to be
            new subscription orders due to the Beam integration.
          </p>
        </div>

        <div className={$$.paragraphBlock}>
          <h3>
            How do we calculate this? First we calculate the number of subscriptions without Beam.
          </h3>
          <p>
            Baseline subscriptions is the number of new subscriptions your brand would have
            converted in the ROI period without Beam present and so without any subscriber signup
            lift. We calculate this by taking the total number of new subscriptions converted during
            the ROI period and using subscriber signup lift to exclude any new subscriptions that
            would be attributed to Beam to find our baseline subscriptions.
          </p>
        </div>
        <div className={$$.paragraphBlock}>
          <h3>How do we use Baseline to calculate Incremental Subscriptions Attributed to Beam?</h3>
          <p>
            Out of the total new subscription orders in the period of the ROI report, we want to
            estimate what number converted due to the Beam integration. To do this, we take our
            Subscriber Signup Lift % and multiply by our Baseline - number of new subscription
            orders that we calculate would have converted without Beam - to estimate the incremental
            new subscriptions that we would attribute to Beam.
          </p>
        </div>
      </div>
      <div className={'mt-7'}>
        <BeamMetricInputBox
          loading={roiResponse.loading}
          data={[
            { type: MetricInputTypes.header, label: 'From Your Monthly ROI Highlight' },
            {
              type: MetricInputTypes.description,
              text: 'Here are the provided metrics we use to calculate Incremental subscriptions attributed to Beam',
            },
            {
              type: MetricInputTypes.metric,
              label: 'Total First Subscriptions',
              value: (oneMonthMetrics?.totalSubscriptionFirstOrders || 0).toLocaleString(),
              tooltip: `The total number of initial subscription orders placed`,
              icon: <UpDownIcon />,
            },
            {
              type: MetricInputTypes.metric,
              label: 'Subscriber Signup Lift',
              value: toPercent({
                input: oneMonthMetrics?.subscriptionSignUpLift || 0,
                precision: 2,
              }),
              tooltip: `Estimation of incremental first subscriptions Beam drives calculated by deducting Non-Beam First Subscription CCR from Beam First Time Subscription CCR and multiplying by a discount factor to account for selective engagement bias`,
              icon: <UpDownIcon />,
            },
          ]}
        />
      </div>

      <div className={'mt-5'}>
        <CalculationBox
          title={'Calculation for Baseline Subscribers'}
          loading={roiResponse.loading}
          operands={[
            {
              value: oneMonthMetrics?.totalSubscriptionFirstOrders || 0,
              description: 'Total First Subscriptions',
            },
            {
              value: '( 1',
              description: '',
            },
            {
              value:
                toPercent({ input: oneMonthMetrics?.subscriptionSignUpLift || 0, precision: 2 }) +
                ' )',
              description: 'Subscriber Signup Lift',
            },
            {
              value: round(Number(oneMonthMetrics?.baselineFirstSubscriptions)) || 0,
              description: 'Baseline Subscribers',
            },
          ]}
          operators={['/', '+', '=']}
        />
      </div>

      <div className={'pt-4 pb-5'}>
        <CalculationBox
          title={'Calculation for Incremental Subscribers Drive by Beam'}
          loading={roiResponse.loading}
          operands={[
            {
              value: round(Number(oneMonthMetrics?.baselineFirstSubscriptions)) || 0,
              description: 'Baseline Subscribers',
            },
            {
              value: toPercent({
                input: oneMonthMetrics?.subscriptionSignUpLift || 0,
                precision: 2,
              }),
              description: 'Subscriber Signup Lift',
            },
            {
              value:
                round(Number(oneMonthMetrics?.incrementalFirstSubscriptionsAttributedToBeam)) || 0,
              description: 'Incremental First Subscriptions Attributed to Beam',
            },
          ]}
          operators={['X', '=']}
        />
      </div>

      <h2>6 Month Sales Lift from Subscriber Signup Lift </h2>
      <div className={$$.paragraphBlock}>
        <h3>How do we think about the revenue impact of additional subscriptions? </h3>
        {isNil(dataWikiData?.sixMonthNonSubscriptionLtv) ? (
          <p>
            We take your brand’s 6 month subscription LTV and multiply by the the incremental
            subscribers attributed to Beam. Because the additional LTV for subscribers accrues over
            a 6-month time horizon, we calculate sales lift for each monthly cohort. We adjust the
            total Incremental LTV based on the number of months that a cohort has been active so
            that we only account for sales lift that your brand has already realized.
          </p>
        ) : (
          <>
            <p>
              We start by estimating the additional lifetime value (LTV) from subscribers, by
              comparing against your brand specific data regarding 6 month LTV for subscribers vs.
              one time purchase orders. These inputs already account for churn, and by subtracting
              one time purchase LTV from subscriber LTV, we calculate the{' '}
              <strong className={$$.paragraphHighlight}>Incremental LTV</strong> of converting a
              customer to a subscription.
            </p>
            <ul className={'list-disc list-inside pl-4 pb-8'}>
              <li>6 month subscriber LTV</li>
              <li>6 month one time customer LTV</li>
            </ul>
            <p>
              Because the additional LTV for subscribers accrues over a 6-month time horizon, we
              calculate sales lift for each monthly cohort. We adjust the total Incremental LTV
              based on the number of months that a cohort has been active so that we only account
              for sales lift that your brand has already realized.{' '}
            </p>
          </>
        )}
      </div>
      <div className={'pb-4'}>
        <div className={'mt-7'}>
          <BeamMetricInputBox
            loading={roiResponse.loading}
            data={[
              { type: MetricInputTypes.header, label: 'From Your Monthly ROI Highlight' },
              {
                type: MetricInputTypes.metric,
                label: 'Incremental First Subscriptions Attributed to Beam',
                value: (
                  round(Number(oneMonthMetrics?.incrementalFirstSubscriptionsAttributedToBeam)) || 0
                ).toLocaleString(),
                tooltip: `The number of additional subscribers Beam converted, calculated by multiplying Total Carts by ${user?.partner.name}'s Subscriber Signup Lift`,
                icon: <UpDownIcon />,
              },
              { type: MetricInputTypes.header, label: `${user?.partner.name}'s Data` },
              {
                type: MetricInputTypes.metric,
                label: '6 month subscriber LTV',
                value: dollarFormat(dataWikiData?.sixMonthSubscriptionLtv || null),
                tooltip: `Lifetime customer value of ${user?.partner.name}'s subscribers over a 6 month period`,
              },
              ...(isNil(dataWikiData?.sixMonthNonSubscriptionLtv)
                ? []
                : [
                    {
                      type: MetricInputTypes.metric,
                      label: '6 month one time purchase LTV',
                      value: dollarFormat(dataWikiData?.sixMonthNonSubscriptionLtv || null),
                      tooltip: `Lifetime customer value of ${user?.partner.name}'s customers making one-time purchases over a 6 month period`,
                    },
                  ]),
            ]}
          />
        </div>
      </div>
      <div className={$$.paragraphBlock}>
        <h3>How do we project the additional revenue?</h3>
        <p>
          For incremental first subscriptions attributed to beam in a given cohort, we estimate how
          much incremental LTV the brand has captured for those customers in the time they have been
          active.
        </p>
      </div>
      <div className={'py-5'}>
        <CalculationBox
          loading={roiResponse.loading}
          title={'Calculation: Single Cohort'}
          operands={[
            {
              value:
                round(Number(oneMonthMetrics?.incrementalFirstSubscriptionsAttributedToBeam)) || 0,
              description: 'Incremental First Subscriptions Attributed to Beam',
            },
            { value: SIX_MONTH_DURATION_VALUE, description: 'Months this Cohort is Active' },
            {
              value: SIX_MONTH_DURATION_VALUE,
              description: (
                <>
                  <span className={'whitespace-nowrap'}>6-Month</span> Period
                </>
              ),
            },
            {
              value: dollarFormat(dataWikiData?.incrementalLtv, 0),
              description: isSubscriptionOnly
                ? '6 Month LTV per subscriber'
                : 'Incremental LTV per subscriber vs one time customer LTV',
            },
            {
              value: dollarFormat(
                calculateSixMonthSalesLift({
                  incrementalFirstSubscriptionsAttributedToBeam:
                    dataWikiData?.incrementalFirstSubscriptionsAttributedToBeam || 0,
                  monthsActive: SIX_MONTH_DURATION_VALUE,
                  maxMonthCount: SIX_MONTH_DURATION_VALUE,
                  incrementalLtv: dataWikiData?.incrementalLtv || 0,
                })
              ),
              description: '6 Month Sales Lift for One Monthly Cohort',
            },
          ]}
          operators={['X', '/', 'X', '=']}
        />
        <div className={cx($$.paragraphBlock)}>
          <p>
            <em>
              Assumption: The value of the subscription will evenly accrue throughout the 6 month
              period (1/6 of the 6 month value for each of the first 6 months they are live)
            </em>
          </p>
        </div>
      </div>
      <div className={$$.paragraphBlock}>
        <h3>
          How do we measure the total 6-month sales lift from increased subscriber conversion?
        </h3>
        <p>
          We apply the single cohort sales lift calculation to each of the cohorts from the 6-month
          period. Adding the lift from each individual cohort, we calculate the full 6-month sales
          lift your brand has realized. To be extra conservative, we do not account for the future
          sales lift that recent cohorts may help drive.
        </p>
      </div>

      <div className={'pt-5'}>
        <CalculationTable
          title={'Calculation: 6 Month Sales Lift Impact'}
          data={roiResponse.data}
          loading={roiResponse.loading}
        />
      </div>
    </article>
  )
}
