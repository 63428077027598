import cx from 'classnames'
import { round } from 'lodash'
import { useContext } from 'react'

import { useBeamSelector } from '../../../../hooks'
import { BeamDataPointBlock } from '../../../../stories/BeamDataPointBlock'
import { BeamTooltip } from '../../../../stories/BeamTooltip'
import { dollarFormat, percentFormat } from '../../../../utils/root'
import { TUser } from '../../../../utils/types'
import { MoneyBagIcon } from '../../../root/iconComponents/MoneyBagIcon'
import { TitleWithTooltip } from '../../../root/TitleWithTooltip'
import { ReactComponent as CartIcon } from '../assets/cartIcon.svg'
import { ReactComponent as GraphIcon } from '../assets/graphIcon.svg'
import { ReactComponent as InfoIcon } from '../assets/infoIcon.svg'
import { ReactComponent as UpwardTrendIcon } from '../assets/upwardTrendIcon.svg'
import $$ from '../overview-page.module.css'
import { overviewTestIds } from '../OverviewPage.helper'
import { DataWikiObject, RoiMetricsObject } from '../OverviewPage.types'
import { TimeframeToggleContext } from '../TimeframeToggleProvider'
import {
  getVisibleOneTimePurchaseMetricsCount,
  shouldShowCCRMetrics,
  shouldWeDisplayAOF,
  shouldWeDisplayAOV,
} from './BusinessImpactModule.helpers'

export const OneTimePurchaseDataSection = ({
  oneMonthMetrics,
  sixMonthMetrics,
  dataWikiMetrics,
}: {
  oneMonthMetrics: RoiMetricsObject
  sixMonthMetrics: RoiMetricsObject
  dataWikiMetrics: DataWikiObject
}) => {
  const { timeframeView } = useContext(TimeframeToggleContext)
  const user: TUser | undefined = useBeamSelector(({ user }) => user)

  const partnerName = user?.partner.name
  const inSixMonthView = timeframeView === 'sixMonth'
  const metricsData = inSixMonthView ? sixMonthMetrics : oneMonthMetrics
  const pctCcrDisplayValue = percentFormat(oneMonthMetrics.pctCcrLift, null, 1, true)
  const shouldShowOneTimePurchaseSection = getVisibleOneTimePurchaseMetricsCount(metricsData) > 0
  const ccrCopy = dataWikiMetrics.abTestDiscountFactor ? `a ${partnerName} specific` : 'an'
  const abTestDiscountFactor =
    dataWikiMetrics.abTestDiscountFactor || dataWikiMetrics.estimatedGlobalAbTestDiscountFactor

  if (!shouldShowOneTimePurchaseSection) return null

  return (
    <div>
      {
        <h3 className={'max-w-fit'}>
          <BeamTooltip
            content={
              'This includes all orders made by first time or returning customers (and excludes any subscription orders if applicable)'
            }>
            <span className={'text-md font-medium items-center flex'}>
              One Time Purchase Data <InfoIcon className={'inline ml-1'} />
            </span>
          </BeamTooltip>
        </h3>
      }
      <div
        className={cx(
          `grid grid-cols-1 space-x-0 space-y-4 desktop:grid-cols-${
            getVisibleOneTimePurchaseMetricsCount(metricsData) <= 2 ? 2 : 3
          } desktop:space-y-0 desktop:space-x-4`,
          $$.businessImpactPart2
        )}>
        {shouldWeDisplayAOV(metricsData) && (
          <div className={`${$$.block}`}>
            <BeamDataPointBlock
              title={
                <TitleWithTooltip
                  title="Average Order Value Lift"
                  tooltipContent={`The difference between average dollars spent per transaction, within the report period date range, between transactions that didn't include a redemption and transactions that included a nonprofit selection`}
                />
              }
              datum={`+${percentFormat(metricsData.nonSubscriptionAverageOrderValueLift, null, 1)}`}
              description={
                <span className={`iconOverrideOverviewPage ${$$.roiMetricComparisonSubheading}`}>
                  <UpwardTrendIcon /> ({dollarFormat(metricsData.nonBeamNonSubscriptionAOV, 2)}{' '}
                  without Beam vs {dollarFormat(metricsData.beamNonSubscriptionAOV, 2)} with Beam)
                </span>
              }
              icon={<MoneyBagIcon />}
              backgroundColor={'--beam-color--lime-50'}
            />
          </div>
        )}

        {shouldWeDisplayAOF(metricsData) && (
          <div className={`${$$.block}`}>
            <BeamDataPointBlock
              title={
                <TitleWithTooltip
                  title="Average Order Frequency Lift"
                  tooltipContent={`The difference between the average number of transactions per unique customer where a donation was not redeemed and the average number of transactions per unique customer where a donation was redeemed`}
                />
              }
              datum={`+${percentFormat(
                metricsData.nonSubscriptionAverageOrderFrequencyLift,
                null,
                1
              )}`}
              description={
                <span className={`iconOverrideOverviewPage ${$$.roiMetricComparisonSubheading}`}>
                  <UpwardTrendIcon />(
                  {metricsData.nonBeamNonSubscriptionOrderFrequency
                    ? round(metricsData.nonBeamNonSubscriptionOrderFrequency, 2)
                    : 'N/A'}{' '}
                  without Beam vs{' '}
                  {metricsData.beamNonSubscriptionOrderFrequency
                    ? round(metricsData.beamNonSubscriptionOrderFrequency, 2)
                    : 'N/A'}{' '}
                  With Beam)
                </span>
              }
              icon={<GraphIcon />}
              backgroundColor={'--beam-color--lime-50'}
            />
          </div>
        )}

        {shouldShowCCRMetrics(oneMonthMetrics) && (
          <div className={$$.block} data-testid={overviewTestIds.roiCcrLiftBlock}>
            <BeamDataPointBlock
              title={
                <TitleWithTooltip
                  title={
                    oneMonthMetrics.isEstimatedCCRIncluded
                      ? 'Est. Cart Completion Rate Lift (CCR)'
                      : 'Cart Completion Rate Lift (CCR)'
                  }
                  tooltipContent={
                    <>
                      Cart completion rate (CCR) is the inverse of cart abandonment rate: the % of
                      unique customers who reach cart that convert (total conversions / total unique
                      carts).
                      <br />
                      <br />
                      To measure the impact that Beam has on CCR, we deduct the CCR of customers
                      converting without a nonprofit selection from the CCR of customers converting
                      with a nonprofit selection. To account for any selective bias, we then
                      multiply this by {ccrCopy} A/B test discount factor:{' '}
                      {percentFormat(abTestDiscountFactor)}
                    </>
                  }
                />
              }
              datum={
                <div className="flex items-center">
                  <span className={'pr-2'}>
                    {oneMonthMetrics.isEstimatedCCRIncluded ? (
                      <em>{`~${pctCcrDisplayValue}`}</em>
                    ) : (
                      `+${pctCcrDisplayValue}`
                    )}
                  </span>
                  {oneMonthMetrics.isEstimatedCCRIncluded && (
                    <BeamTooltip
                      content={`Since Beam doesn't currently have access to ${partnerName}'s cart data, we've provided a very conservative view of sales lift assuming we only drive a ${pctCcrDisplayValue} improvement in ${partnerName}'s cart completion rate, a figure we've consistently exceeded in A/B tests`}>
                      <div className={'flex items-center'}>
                        <InfoIcon className="inline" />
                      </div>
                    </BeamTooltip>
                  )}
                </div>
              }
              description={
                <span className={`iconOverrideOverviewPage ${$$.roiMetricComparisonSubheading}`}>
                  <UpwardTrendIcon />({percentFormat(oneMonthMetrics?.totalCCR, null, 0)} Overall vs{' '}
                  {percentFormat(oneMonthMetrics.nonBeamCCR, null, 0)} Non-Beam)
                </span>
              }
              icon={<CartIcon />}
              backgroundColor={'--beam-color--lime-50'}
            />
          </div>
        )}
      </div>
    </div>
  )
}
