import { createColumnHelper, Row } from '@tanstack/react-table'
import { noop, truncate } from 'lodash'
import { useContext } from 'react'

import { BeamEditOrDeleteButton } from '../../../../stories/BeamEditOrDeleteButton'
import { BeamSticker } from '../../../../stories/BeamSticker'
import { BEAM_STICKER_VARIANT } from '../../../../stories/BeamSticker/BeamSticker'
import { BeamTable } from '../../../../stories/BeamTable'
import { BeamTooltip } from '../../../../stories/BeamTooltip'
import { formatDate, toInputDatetimeFormat } from '../../../../utils/helpers/formatDate'
import { TUser } from '../../../../utils/types'
import { PromoModal } from '../PromoModalsComponent/PromoModalsComponent'
import { ModalContext } from './CampaignPage'
import { CampaignTableLoadingOverlay } from './CampaignTableLoadingOverlay'
import { CampaignPromoObject } from './promo.types'

interface UpcomingAndLivePromosTableProps {
  data: CampaignPromoObject[]
  /**
   * Loading state for the table
   */
  loading: boolean
  user: TUser
}

interface PromosTableColumnsTypes extends CampaignPromoObject {
  edit: any
}

function promoCanBeEdited(promo: Row<PromosTableColumnsTypes>) {
  // does the promo start more than 24 hours from now
  return (
    promo?.original &&
    promo.original.boostStatus !== 'Live' &&
    new Date(promo.original.startTime).getTime() > new Date().setHours(new Date().getHours() + 24)
  )
}

export const UpcomingAndLivePromosTable = (props: UpcomingAndLivePromosTableProps) => {
  const { setModal, setCurrentPromo } = useContext(ModalContext)
  const { data, loading = false } = props
  const columnHelper = createColumnHelper<PromosTableColumnsTypes>()

  function handleEditOrDeleteSelectEvent(
    selectedValue: 'edit' | 'delete',
    promo: CampaignPromoObject
  ) {
    setCurrentPromo(promo)
    switch (selectedValue) {
      case 'delete':
        setModal(PromoModal.DeleteAPromoConfirmation)
        break
      case 'edit':
        setModal(PromoModal.BoostANonprofit)
        break
    }
  }

  const convertRowToPromo = (rowData: PromosTableColumnsTypes): CampaignPromoObject => {
    const promoStartDateString = rowData?.startTime && toInputDatetimeFormat(rowData.startTime)
    const promoEndDateString = rowData?.endTime && toInputDatetimeFormat(rowData.endTime)
    return {
      id: rowData?.id,
      name: rowData?.name,
      promoText: rowData.promoText || null,
      chainId: rowData.chainId,
      availableToWholeChain: rowData?.availableToWholeChain,
      boostAllNonprofits: !!rowData?.boostAllNonprofits,
      boostedStoreNonprofitIds: rowData?.boostedStoreNonprofitIds,
      startTime: promoStartDateString,
      endTime: promoEndDateString,
      multiplier: rowData.multiplier,
      colorPrimary: rowData.colorPrimary,
      foregroundColor: rowData.foregroundColor,
      boostStatus: '',
    }
  }

  const columns = [
    columnHelper.accessor('edit', {
      header: 'Edit',
      cell: ({ row }) => {
        const disabled = !promoCanBeEdited(row)
        const promo = convertRowToPromo(row.original)

        return disabled ? (
          <BeamTooltip content="We can not edit live or upcoming campaigns launching within the next 24 hours. Please contact your CSL.">
            <BeamEditOrDeleteButton disabled={disabled} selectHandler={noop} />
          </BeamTooltip>
        ) : (
          <BeamEditOrDeleteButton
            disabled={disabled}
            selectHandler={(selectedValue: 'edit' | 'delete') => {
              handleEditOrDeleteSelectEvent(selectedValue, promo)
            }}
          />
        )
      },
    }),
    columnHelper.accessor('name', {
      header: 'Campaign Name',
    }),
    columnHelper.accessor('startTime', {
      header: 'Date Range',
      cell: ({ row }) => {
        const rowData = row.original

        return (
          <>
            {formatDate(rowData.startTime, 'MM/dd/yyyy')} -{' '}
            {formatDate(rowData.endTime, 'MM/dd/yyyy')}
          </>
        )
      },
    }),
    columnHelper.accessor('multiplier', {
      header: 'Boost Amount',
      cell: ({ row }) => {
        const rowData = row.original

        return <>{rowData.multiplier}x</>
      },
    }),
    columnHelper.accessor('boostedNonprofits', {
      header: 'Boosted Nonprofits',
      cell: ({ row }) => {
        const rowData = row.original

        return (
          <BeamTooltip content={rowData.boostedNonprofits}>
            {truncate(rowData.boostedNonprofits, {
              length: 50,
              omission: '...',
            })}
          </BeamTooltip>
        )
      },
    }),
    columnHelper.accessor('boostStatus', {
      header: 'Boost Status',
      cell: ({ row }) => {
        const rowData = row.original
        switch (rowData.boostStatus) {
          case 'Live':
            return <BeamSticker label={'🚀 Live'} variant={BEAM_STICKER_VARIANT.positive} />
          case 'Upcoming':
            return <BeamSticker label={'Upcoming'} variant={BEAM_STICKER_VARIANT.pending} />
        }
        return <></>
      },
    }),
  ]

  return (
    <div className={'relative'} aria-live={'polite'}>
      <CampaignTableLoadingOverlay loading={loading} />
      <BeamTable
        columns={columns}
        data={data}
        noDataMessage={'No reports uploaded to Partner Portal yet'}
        enableSorting
      />
    </div>
  )
}
